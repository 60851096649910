<template>
    <div>
        <loading-card :loading="!loaded">
            <div class="card-header">{{ $t('crud.payment_methods._show') }}</div>
            <div class="card-body">
                <payment-methods-form-component @submit="updateFromRoute" :record="record" :loading="loading"></payment-methods-form-component>
            </div>
        </loading-card>
    </div>
</template>

<script>
    import {config, functions} from '../crud/mixin_config';
    import PaymentMethodsFormComponent from "./Form";

    export default {
        name: 'pickup-locations-show-component',
        mixins: [functions],
        components: {PaymentMethodsFormComponent},
        data() {
            return {
                crud: config('payment_methods')
            };
        }
    }
</script>
